import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Common from '../../utils/common';

export default class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            build: ''
        }
        var param = {};
        this.state.build = Common.getPage('footer', param);


    }
    render() {
        window.htmlFooter += this.state.build;
        return (
            <footer dangerouslySetInnerHTML={{ __html: this.state.build }}>

            </footer>
        )
    }
}