import React from 'react';
import Common from '../../utils/common';

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            build: ''
        }
        var param = {};
        this.state.build = Common.getPage('header', param);
    }

    render() {
        // $(this.state.build).at
        window.html += this.state.build;
        return (
            <header dangerouslySetInnerHTML={{ __html: this.state.build }}>

            </header>
        )

    }
}