import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

// import Home from '../components/home/home';

// import Products from '../components/product/list';
// import Product from '../components/product/detail';
// import Articles from '../components/article/list';
// import Article from '../components/article/detail';
// import Landing from '../components/landing-page/landing';
//import Search from '../components/search/list';

const Home = lazy(() => import('../components/home/home'));
const Products = lazy(() => import('../components/product/list'));
const Product = lazy(() => import('../components/product/detail'));

const Courses = lazy(() => import('../components/course/list'));
const Course = lazy(() => import('../components/course/detail'));

const Payment = lazy(() => import('../components/payment/detail'));

const Bookings = lazy(() => import('../components/booking/list'));
const Booking = lazy(() => import('../components/booking/detail'));

const Articles = lazy(() => import('../components/article/list'));


const Article = lazy(() => import('../components/article/detail'));

const Resorts = lazy(() => import('../components/resort/list'));


const Resort = lazy(() => import('../components/resort/detail'));

const Events = lazy(() => import('../components/event/list'));
const Event = lazy(() => import('../components/event/detail'));

const EventCourses = lazy(() => import('../components/eventcourse/list'));
const EventCourse = lazy(() => import('../components/eventcourse/detail'));

const Albums = lazy(() => import('../components/album/list'));
const Album = lazy(() => import('../components/album/detail'));

const Books = lazy(() => import('../components/book/list'));
const Book = lazy(() => import('../components/book/detail'));

const Users = lazy(() => import('../components/user/list'));
const User = lazy(() => import('../components/user/detail'));
const Search = lazy(() => import('../components/search/list'));

const Contact = lazy(() => import('../components/contact/list'));
const ShoppingCart = lazy(() => import('../components/shoppingcart/list'));
const Landing = lazy(() => import('../components/landing-page/landing'));


class App extends React.Component {

    componentWillUnmount() {
        this.unlisten();
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            // $("script").last().after('<script  src="https://static.foodizzi.com/Templates/BornToGiveCharity/js/init.js"></script>');
        });
    }
    render() {
        var temp = '/'
        if (window.languageCode != "") {
            temp = "/:language";
        }
        return (
            <Suspense fallback={<div className="se-pre-con"></div>}>
                <Switch>
                    <Route exact path={temp} component={Home} />
                    <Route path={"/:language/bai-viet/:id/:name"} component={Article} />
                    <Route path={"/:language/bai-viet/:friendlyUrl"} component={Article} />
                    <Route path={"/:language/article/:friendlyUrl"} component={Article} />                    
                    
                    <Route path={"/:language/resort/:friendlyUrl"} component={Resort} />
                    <Route path={"/:language/resorts/:friendlyUrl"} component={Resorts} />

                    {/* <Route path={"/:language/danh-sach-bai-viet/:id/:name"} component={Articles} /> */}
                    <Route path={"/:language/danh-sach-bai-viet/:friendlyUrl/:page"} component={Articles} />

                    <Route path={"/:language/danh-sach-bai-viet/:friendlyUrl"} component={Articles} />
                    <Route path={"/:language/articles/:friendlyUrl"} component={Articles} />

                    <Route path={"/:language/danh-sach-thanh-vien"} component={Users} />
                    <Route path={"/:language/thanh-vien/:id/:name"} component={User} />
                    
                    <Route path={"/:language/danh-sach-su-kien"} component={Events} />
                    <Route path={"/:language/events"} component={Events} />
                    <Route path={"/:language/su-kien/:friendlyUrl"} component={Event} />
                    <Route path={"/:language/event/:friendlyUrl"} component={Event} />

                    <Route path={"/:language/danh-sach-chuong-trinh"} component={EventCourses} />
                    <Route path={"/:language/eventcourses"} component={EventCourses} />
                    <Route path={"/:language/chuong-trinh/:friendlyUrl"} component={EventCourse} />
                    <Route path={"/:language/eventcourse/:friendlyUrl"} component={EventCourse} />

                    <Route path={"/:language/danh-sach-album/:friendlyUrl/:page"} component={Albums} />
                    <Route path={"/:language/danh-sach-album/:friendlyUrl"} component={Albums} />
                    
                    <Route path={"/:language/albums/:friendlyUrl"} component={Albums} />                    
                    <Route path={"/:language/albums/:friendlyUrl/:page"} component={Albums} />                    
                    <Route path={"/:language/album/:friendlyUrl"} component={Album} />

                    <Route path={"/:language/danh-sach-sach"} component={Books} />
                    <Route path={"/:language/books"} component={Books} />                    
                    <Route path={"/:language/sach/:friendlyUrl"} component={Book} />
                    <Route path={"/:language/book/:friendlyUrl"} component={Book} />

                    <Route path={"/:language/danh-sach-phong"} component={Bookings} />
                    <Route path={"/:language/phong/:friendlyUrl"} component={Booking} />

                    <Route path={"/:language/danh-sach-san-pham/:friendlyUrl/:page"} component={Products} />                   

                    <Route path={"/:language/danh-sach-san-pham/:friendlyUrl"} component={Products} />
                    <Route path={"/:language/products/:friendlyUrl"} component={Products} />

                    <Route path={"/:language/san-pham/:id/:name"} component={Product} />
                    <Route path={"/:language/san-pham/:friendlyUrl"} component={Product} />
                    <Route path={"/:language/product/:friendlyUrl"} component={Product} />

                    

                    <Route path={"/:language/tim-kiem/:keyword"} component={Search} />
                    <Route path={"/:language/tim-kiem"} component={Search} />

                    <Route path={"/:language/search/:keyword"} component={Search} />
                    <Route path={"/:language/search"} component={Search} />

                    <Route path={"/:language/gio-hang"} component={ShoppingCart} />

                    <Route path={"/:language/lien-he"} component={Contact} />
                    {/* <Route path={"/:language/pages/:name/:block"} component={Landing} /> */}

                    <Route path={"/:language/pages/:id/:name"} component={Landing} />
                    <Route path={"/:language/pages/:friendlyUrl"} component={Landing} />

                    {/* <Route path="/pages/:id/:name" component={Landing} /> */}

                    <Route path={"/:language/danh-sach-khoa-hoc/:friendlyUrl"} component={Courses} />
                    <Route path={"/:language/courses/:friendlyUrl"} component={Courses} />
                    
                    <Route path={"/:language/khoa-hoc/:friendlyUrl"} component={Course} />
                    <Route path={"/:language/course/:friendlyUrl"} component={Course} />
                    <Route path={"/:language/payment/:orderId"} component={Payment} />
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </Suspense>


            // <Switch>
            //     <Route exact path="/" component={Home} />
            //     <Route path={"/danh-sach-bai-viet/:id/:name"} component={Articles} />
            //     <Route path={"/danh-sach-bai-viet"} component={Articles} />
            //     <Route path="/bai-viet/:id/:name" component={Article} />
            //     <Route path={"/danh-sach-san-pham/:id/:name"} component={Products} />
            //     <Route path={"/danh-sach-san-pham"} component={Products} />
            //     <Route path={"/san-pham/:id/:name"} component={Product} />
            //     <Route path="/pages/:id/:name" component={Landing} />

            // </Switch>
        )
    }
}

function NoMatch() {

    window.location.href = '/404.html?...';
}


export default withRouter(App);