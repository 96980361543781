import $ from "jquery";
import Configuration from "../config";
import moment from "moment";
// function addMinutes(date, minutes) {
//     return new Date(date.getTime() + minutes*60000);
// }

// function getDateTimeUtc(){                        
//     var date = new Date(); 
//     var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
//      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());  
//      console.log(now_utc)  ;
//      return new Date(now_utc);
// }
Number.prototype.formatMoney = function (c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "," : d,
        t = t == undefined ? "." : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "") + "đ";
};


Number.prototype.formatNumber = function (c, d, t) {
    if(this == undefined) return '-';
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "," : d,
        t = t == undefined ? "." : t,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

var Common = {
    page:1,
    rootData: null,
    detailData: null,
    listData: [],
    preParamIds: '',
    staticUrlCurrent: 0,
    activePage: 1,
    totalItemsCount: 0,
    tempDataSection: {},
    buildPrice: function (discounts, price) {
        if (discounts == undefined || discounts.length == 0) {
            return {
                price: price,
                oldPrice: undefined,
                percent: undefined,
                cash: undefined
            };
        } else {
        var now = moment(Configuration.datetimeNow);
            //console.log(now);
            var tempPrice = price;
            var percent = 0;
            var cash = 0;
            $.each(discounts,
                function (i, val) {
                    //if (val.from <= Configuration.datetimeNow && val.to >= Configuration.datetimeNow) {
                    if (moment(val.from) <= now && moment(val.to) >= now) {
                        if (val.resultType == 0) {
                            tempPrice = tempPrice - (tempPrice * val.resultValue) / 100;
                            percent += parseInt(val.resultValue);
                        } else {
                            tempPrice = tempPrice - val.resultValue;
                            cash += parseInt(val.resultValue);
                            percent += parseInt(((price - tempPrice)/price)*100);
                        }
                    }
                });
                if(price == tempPrice){
                    return {
                        price: price,
                        oldPrice: undefined,
                        percent: undefined,
                        cash: undefined
                    };
                }
            return {
                price: tempPrice,
                oldPrice: price,                
                percent: percent,
                cash: cash,                
            };
        }
    },

    getAvailableBookingRooms: function (option) {
        var query = `
    {
        availablebookingrooms(param:{merchantId:"${Common.getCookie(
            "merchantId"
        )}",languageId:"${window.languageId}",
        ${
            option.from && option.to
                ? `from:"${option.from}",to:"${option.to}"`
                : ""
            },
            ${
            option.capacity &&
            `capacity:${option.capacity}`

            }
        })
        {
            items{
            id        ,
              name,
              price,
              subDescription,
              capacity, 
              utilities,
              bookingRoomStatus,
              bookingRooms {
                id
              }, 
              images{
                  id,
                  path,
                  isFeatured,
                  displayOrder
              }

            },
            totalCount,
            success,
            message
          }
      }
    `;
        var returnData = [];
        var xhr = new XMLHttpRequest();
        //Starts the variable xhr as the variable for the request
        xhr.open("POST", Configuration.url_graphQl, false);
        xhr.setRequestHeader("Content-type", "application/json");
        // xhr.setRequestHeader('Authorization', 'Basic ' + btoa('amara:dSPKMcdQkG5X97b'));


        //Runs method 'open' which defines the request

        //Sends the request
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var temp = JSON.parse(xhr.responseText);
                    returnData = temp.data.availablebookingrooms.items;
                } else {
                    console.error(xhr.statusText);
                }
            }
        };
        xhr.onerror = function (e) {
            console.error(xhr.statusText);
        };
        xhr.send(JSON.stringify({ query: query }));
        //console.log(returnData);
        return returnData;


        // return axios
        //     .post(Configuration.url_graphQl, { query: query })
        //     .then((res) => {
        //         return res.data.data;
        //     });
    },
    getParameterByName: function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    getParams: function (url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]).replace(/\+/g, " ");
        }
        //console.log(params);
        return params;
    },
    getPage: function (name, param, page) {
        
        if(page != undefined){
            Common.page = page;
        }
        var build = '';
        var queries = [{
            match: {
                type: 128,
            },
            match: {
                parentId: "00000000-0000-0000-0000-000000000000",
            }
        },
        {
            match: {
                'themeWebs.id': Common.getCookie('themeWebId')
            }
        }];
        if (name == 'landing') {
            queries.push({
                match_phrase: {
                    friendlyUrl: param.friendlyUrl,
                }
            })
        }
         else if (name == 'event' && param['friendlyUrl'] != undefined)
        {
            Common.detailData = this.getData("event|param:friendlyUrl:friendlyUrl|includes:", param);
            
            if(Common.detailData.data[0].pageLayouts != undefined && Common.detailData.data[0].pageLayouts.length > 0){
                queries.push({
                    match_phrase: {
                        friendlyUrl: Common.detailData.data[0].pageLayouts[Math.floor(Math.random()*Common.detailData.data[0].pageLayouts.length)],
                    }
                })
            }else{
                queries.push({
                    match_phrase: {
                        name: name,
                    }
                })
            }
          
        }
        else {
            queries.push({
                match_phrase: {
                    name: name,
                }
            })
        }
        var tempPage = Common.queryData(Configuration.categoryIndex, Configuration.categoryType, {
            async: false,
            includes: [],
            queries: queries,
            from: 0,
            size: 100
        }).data;

        console.log(tempPage);
     
        if (tempPage.length == 0) {
            build = 'Chưa có dữ liệu khối.';            
            return;
        }
        if (tempPage.length > 1 && param['friendlyUrl'] != undefined) {            
            tempPage = tempPage.filter(e => e.friendlyUrl === param['friendlyUrl']).slice(0, 1);           
        }

        var components = [];

        if (name.toLowerCase() === 'header' || name.toLowerCase() === 'footer' || name.toLowerCase() === 'firstviewhome' || name.toLowerCase() === 'afterviewhome') {
            components = [...tempPage];
        } else {
            console.log(tempPage);
            console.log(tempPage.find(e=>e.name == name));
            try {
                var tempId = '';
                tempId = tempPage[0].id;
                if(name.toLowerCase() == 'event'){
                    tempId = tempPage//[0]
                    .find(e=>e.name.toLowerCase() == name.toLowerCase())
                    .id;
                }else{
                    tempId = tempPage[0].id;
                }
                components = [...Common.queryData(Configuration.categoryIndex, Configuration.categoryType, {
                    async: false,
                    includes: [],
                    queries: [{
                        match: {
                            parentId: tempId,
                        }
                    }],
                    sorts: [{
                        displayOrder: "asc",
                    }],
                    from: 0,
                    size: 1000
                }).data];
            }catch{
                console.log(tempPage);
            }
            
        }


        if (param['friendlyUrl'] != undefined) {
            switch (name) {
                case 'landing':
                    Common.detailData = this.getData("page|param:friendlyUrl:friendlyUrl|includes:", param);
                    break;
                case 'article':
                    Common.detailData = this.getData("article|param:friendlyUrl:friendlyUrl|includes:", param);                    
                    break;
                case 'resort':
                    Common.detailData = this.getData("addressresort|param:productType:localStorage.productType|param:friendlyUrl:friendlyUrl|includes:", param);                    
                    break;
                case 'articles':
                    Common.detailData = this.getData("category|param:friendlyUrl:friendlyUrl|param:type:2|includes:", param);
                    break;
                // case 'event':
                //     Common.detailData = this.getData("event|param:friendlyUrl:friendlyUrl|includes:", param);
                //     break;
                case 'album':
                    Common.detailData = this.getData("album|param:friendlyUrl:friendlyUrl|includes:", param);
                    break;
                case 'albums':
                    Common.detailData = this.getData("album|param:friendlyUrl:friendlyUrl|includes:", param);
                    break;
                case 'eventcourse':
                    Common.detailData = this.getData("eventcourse|param:friendlyUrl:friendlyUrl|includes:", param);
                    break;
                case 'product':
                    Common.detailData = this.getData("product|param:friendlyUrl:friendlyUrl|includes:", param);
                    window.Merchant.product = Common.detailData.data[0];
                    if(!Common.getCookie("recentlyProduct").includes(Common.detailData.data[0].id)){
                        Common.setCookie("recentlyProduct",Common.getCookie("recentlyProduct") +"," + Common.detailData.data[0].id, 30);        
                    }                    
                    break;
                case 'products':
                    Common.detailData = this.getData("category|param:friendlyUrl:friendlyUrl|param:type:3|includes:", param);
                    break;
                case 'course':
                    Common.detailData = this.getData("product|param:friendlyUrl:friendlyUrl|includes:", param);
                    break;
                case 'courses':
                    Common.detailData = this.getData("category|param:friendlyUrl:friendlyUrl|param:type:3|includes:", param);
                    break;
                    
                case 'bookings':
                    Common.listData =
                        this.getAvailableBookingRooms({
                            from: param['fromDateTime'],// from.toUTCString(),
                            to: param['toDateTime'],// to.toUTCString(),
                            capacity: param["capacity"],

                        });
                    //console.log(Common.detailData);
                    // .then((res) => setRooms(res.availablebookingrooms.items))
                    // .finally(() => setLoading(false));

                    //this.getData("category|param:friendlyUrl:friendlyUrl|param:type:3|includes:", param);
                    break;
                default:
                    break;

                  
            }

            if(Common.detailData != null){
                var tempData = Common.detailData.data[0];                
                
                if(tempData != undefined){

                    if(tempData.categories != undefined){
                        Common.tempDataSection["categoryIds"] = tempData.categories.map(e => e.id).toString();
                    }

                    if(tempData.metaKeyword != undefined){
                        var link = document.createElement('meta');
                        link.setAttribute('property', 'keywords');
                        link.content = tempData.metaKeyword;
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
    
                    if(tempData.metaName != undefined){
                        var link = document.createElement('meta');
                        link.setAttribute('property', 'title');
                        link.content = tempData.metaName;
                        document.getElementsByTagName('head')[0].appendChild(link);
                        document.title = tempData.metaName;
                    }
    
                    if(tempData.metaImage != undefined){
                        var link = document.createElement('meta');
                        link.setAttribute('property', 'image');
                        link.content = tempData.metaImage;
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
    
                    
                    if(tempData.metaDescription != undefined){
                        var link = document.createElement('meta');
                        link.setAttribute('property', 'description');
                        link.content = tempData.metaDescription;
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                    var link = document.createElement('meta');
                    link.setAttribute('property', 'url');
                    link.content = document.location;
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                
                
            }

            if (name != 'landing' && name != 'bookings') {
                Common.rootData = Common.detailData.data[0];
            }


            console.log(Common.detailData);
        }

        $.each(components, function (i, component) {
            var repeats = [];
            if (component.description == undefined || component.description.trim() === '') return;
            var tempHtml = '<div>' + component.description + '</div>';
            var htmlObject = $(tempHtml);
            var tempHtml = htmlObject[0].innerHTML;
            if (htmlObject.attr('repeat') !== undefined) {
                repeats.push(htmlObject[0]);
            }
            else {
                $.each($(htmlObject).find('[repeat]'), function (i, val) {
                    repeats.push(val);
                });
            }


            param['relationId'] = component.relationIds.toString();
            $.each(repeats, function (i, section) {
                var dataRepeat = $(section).attr('repeat');
                
            if(name == 'events')
            {
                console.log(dataRepeat);
            }
                if (dataRepeat.toLowerCase() === 'component') {
                    var tempArray = [];
                    tempArray.push(component);
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                } else if (dataRepeat.toLowerCase() === 'languages') {
                    var tempArray = [];
                    tempArray = window.Merchant.data.languages;
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                } else if (dataRepeat.toLowerCase() === 'currentlanguage') {
                    var tempArray = [];
                    tempArray = [{ languageCode: window.languageCode }];
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                } else if (dataRepeat.toLowerCase() === 'detaildata' && Common.detailData != null) {
                    var tempArray = [];
                    tempArray = Common.detailData.data;
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                }
                else if (dataRepeat.toLowerCase() === 'listdata' && Common.listData != null) {
                    var tempArray = [];
                    tempArray = Common.listData;
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                }
                else if (dataRepeat.toLowerCase() === 'param') {
                    var tempArray = [JSON.parse(JSON.stringify(Common.getParams(window.location.href)))];
                    console.log(tempArray);
                    //console.log(tempArray);
                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, tempArray));
                }
                else if(dataRepeat.toLowerCase() === 'raw')
                {                    
                    var tempSectionObject = $(section);
                    window.Merchant.modalHtml.push({id:$(tempSectionObject).attr("id"), html: section.outerHTML});
                    console.log(window.Merchant.modalHtml);
                }
                else {
                    var temp = Common.getData($(section).attr('repeat'), param);
                    if (temp == undefined) return;
                    if (i == 0) {
                        Common.rootData = temp.data[0];
                        console.log("===============================");
                        console.log(temp.data);
                        console.log(Common.rootData);
                        console.log("===============================");
                    }
                    var data = temp.data;
                    //param['parent'] = temp.data[0];
                    var total = temp.total;
                    if (param["paramId"] !== undefined && temp != undefined && data[0] != undefined && param["paramId"] == data[0].id) {
                        if (data[0].metaName == undefined || data[0].metaName === '') {
                            document.title = data[0].name;
                        } else {
                            document.title = data[0].metaName;
                        }

                        if (data[0].metaKeyword == undefined || data[0].metaKeyword === '') {
                            document.keywords = data[0].name;
                        } else {
                            document.keywords = data[0].metaKeyword;
                        }

                        if (data[0].metaDescription == undefined || data[0].metaDescription === '') {
                            document.description = data[0].subDescription;
                        } else {
                            document.description = data[0].metaDescription;
                        }

                        document.image = data[0].metaImage;
                    }
                    
                    Common.totalItemsCount = total;    
                    if ($(section).attr('repeat').includes('paging')) {
                        //Common.totalItemsCount = total;    
                        window.Merchant.totalItemsCount = total;
                        window.Merchant.totalItemsCount = total;                        
                    }
                    
                    //if(total < page*size)

                    tempHtml = tempHtml.replace(section.outerHTML, Common.replaceSectionTemp(section, data, param));


                    if (data.length == 0) {
                        var $laboEmptyContent = $(".laboEmptyContent");
                        var $laboEmptyContentHide = $(".laboEmptyContentHide");
                        if ($laboEmptyContent.length) {
                            $laboEmptyContent.show();
                        }
                        if ($laboEmptyContentHide.length) {
                            $laboEmptyContentHide.hide();
                        }

                    }
                }

            });
            build += tempHtml;
        })

        return build.replace( /foodizzi.com/ig,"izzi.asia");
    },

    findVariable(myRegex, myString) {
        var match = myRegex.exec(myString);
        var variables = [];
        while (match != null) {
            variables.push(match[0])
            match = myRegex.exec(myString);
        }
        return variables;
    },


    getData: function (config, params, parent, type) {        
        var tempArray = config.split('|');
        var index = '';
        var type = '';
        var option = {};
        option.sorts = [];
        option.queries = [];
        

        
        switch (tempArray[0].toLowerCase()) {
            case 'page':
                index = 'pages';
                type = 'page';
                option.includes = []
                option.size = 10000;//Common.getParameterByName("page") == undefined ? Configuration.pageSizeResort:parseInt(Common.getParameterByName("page"))*Configuration.pageSizeResort;
                break;
            case 'quiz':
                index = 'quizs';
                type = 'quiz';
                option.includes = ["id", 'name', 'images', 'createdDate']
                option.size = 10000;//Common.getParameterByName("page") == undefined ? Configuration.pageSizeResort:parseInt(Common.getParameterByName("page"))*Configuration.pageSizeResort;
                break;
            case 'addressresort':
                index = 'addressresorts';
                type = 'addressresort';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = 10000;//Common.getParameterByName("page") == undefined ? Configuration.pageSizeResort:parseInt(Common.getParameterByName("page"))*Configuration.pageSizeResort;
                break;
            case 'user':
                index = 'users';
                type = 'user';
                option.includes = ["id", 'name', 'email', 'mobile']
                option.size = 10000;
                break;
            case 'address':
                index = 'eventaddresses';
                type = 'eventaddress';
                option.includes = ["id", 'name', 'latitude', 'longitude']
                option.size = 10000;
                break;
            case 'evoucher':
                index = 'evouchers';
                type = 'evoucher';
                option.includes = ["id", 'name']
                option.size = 10000;
                break;
            case 'promotion':
                index = 'promotions';
                type = 'promotion';
                option.includes = ["id", 'name']
                option.size = 10000;
                break;
            case 'article':
                index = 'articles';
                type = 'article2';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = Configuration.pageSizeArticles;
                break;
            case 'event':
                index = 'events';
                type = 'event';
                option.includes = ["id", 'name', 'languageCode', 'startDateTime', 'endDateTime', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = Configuration.pageSizeArticles;
                
                break;
            case 'eventcourse':
                index = 'eventcourses';
                type = 'eventcourse';
                
                option.includes = ["id", 'name', 'languageCode', 'startDateTime', 'endDateTime', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = Configuration.pageSizeArticles;                
                break;
            case 'album':
                index = 'albums';
                type = 'album';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = Configuration.pageSizeArticles;
                break;
            case 'book':
                index = 'books';
                type = 'book';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'subDescription', 'images', 'createdDate']
                option.size = Configuration.pageSizeArticles;
                break;
            case 'language':
                index = 'languages';
                type = 'language';
                option.includes = ["id", 'name', 'isFeatured', 'imagePath']
                option.size = Configuration.pageSizeArticles;
                break;
                //productexchangepoint
            case 'product':
                index = 'products';
                type = 'product';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'price', 'subDescription', 'images', 'displayOrder', 'code']
                option.size = Configuration.pageSizeProducts;
                break;

            case 'productexchangepoint':
                index = 'productexchangepoints';
                type = 'productexchangepoint';
                option.includes = ["id", 'name', 'languageCode', 'friendlyUrl', 'price', 'subDescription', 'images', 'displayOrder', 'code', 'point']
                option.size = Configuration.pageSizeProducts;
                break;
            case 'form':
                index = 'forms';
                type = 'form';
                option.includes = ["id", 'name', 'description']
                option.size = Configuration.pageSizeProducts;
                break;
            case 'attribute':
                index = 'attributes';
                type = 'attribute';
                option.includes = ["id", 'name', 'description']
                //option.size = Configuration.pageSizeProducts;
                break;
            case 'category':
                index = 'categories';
                type = 'category';
                option.includes = [];// ["id", 'name', 'images', 'createdDate', 'subDescription']
                break;
            case 'shoppingcart':
                index = 'shoppingcarts';
                type = 'shoppingcart';
                option.includes = [];// ["id", 'name', 'images', 'createdDate', 'subDescription']
                break;
            default:
                return;

                
        }        

        //option.queries = [];

        var arrayFunction = tempArray.slice(1);
        var tempValueParam = '';
        var tempNameParam = '';
        var isSortByParamIds = false;
        var sortByParamIds = '';
        var sortByParamName = '';
        
        if (arrayFunction.length > 0) {

            $.each(arrayFunction, function (i, val) {
                var tempArray = val.split(':');
                var functionName = tempArray[0];
                if (val.split(':')[1] == undefined) return;

                switch (functionName.toLowerCase()) {
                    case 'includes':
                        if (val.split(':')[1].trim() == '') {
                            option.includes = [];
                        } else {
                            option.includes = val.split(':')[1].split(',');
                        }

                        break;
                    case 'from':
                        if (val.split(':')[1] == 'paging') {
                            option.from = (Common.activePage - 1) * parseInt(option.size);
                            
                        } else {
                            option.from = val.split(':')[1];
                        }
                        break;
                    case 'size':
                        option.size = Common.page*val.split(':')[1];
                        if(config.includes("paging")){
                            window.Merchant.pageSize = Common.page*val.split(':')[1];
                        }
                        break;
                    // case 'from':
                    //     option.from = val.split(':')[1];
                    //     break;
                    case 'sort':                        
                        var sort = {};
                        var lat = Common.getParameterByName("lat");
                        var lon = Common.getParameterByName("lon");
                        console.log(tempArray[1].trim());
                        if(lat != undefined && lon != undefined&& lat != '' && lon != '' &&  (tempArray[1].trim() == "distance")){                            
                            sort = JSON.parse('{"_geo_distance": {"position": {"lat": '+ lat +',"lon": '+ lon +'},"order": "asc", "unit": "km","distance_type": "plane"}}'); 
                            console.log(sort);
                        }else{
                            if(tempArray[1].includes('sessionStorage')){
                                
                                var tempSort = sessionStorage.getItem(tempArray[1].split('.')[1]);
                                if(tempSort != undefined){
                                    var tempSubSort = tempSort.split(":");
                                    sort[tempSubSort[0].trim()] = tempSubSort[1];
                                }
                                
                            }else{
                                sort[tempArray[1].trim()] = tempArray[2];
                            }
                            
                        }
                        //console.log([sort]);
                        option.sorts.push(sort);
                        break;
                    case 'range':                              
                        if (tempArray[3] === 'url'){
                            var tempRange = tempArray[1].split(".");
                            tempArray[3] = Common.getParameterByName(tempRange[tempRange.length - 1].trim());
                            if(tempArray[3] == null || tempArray[3] == "") break;
                            if(tempRange[tempRange.length - 1].trim().toLowerCase().includes("date")){
                                var tempDate = tempArray[3].split("/");
                                tempArray[3] = tempDate[2] + "-" + tempDate[1] + "-" + tempDate[0];
                            }
                        }
                         else if (tempArray[3].includes('sessionStorage')) {                            
                            tempArray[3] = sessionStorage.getItem(tempArray[3].split('.')[1]);
                            //sessionStorage.getItem
                            
                        } 
                        else if (tempArray[3].includes('localStorage')){
                            tempArray[3] = localStorage.getItem(tempArray[3].split('.')[1]);
                        }

                        if(tempArray[3] == null || tempArray[3] == undefined) break;
                        var range = '{"range":{"'+tempArray[1].trim()+'":{"'+tempArray[2].trim()+'":"'+tempArray[3].trim()+'"}}}';
                        option.queries.push(
                            JSON.parse(range)
                        )
                        break;
                    case 'filter':         
                        if(tempArray[1].trim() == "geodistance"){
                            var lat = Common.getParameterByName("lat");
                            var lon = Common.getParameterByName("lon");
                            if(lat & lon){
                                var distance = '{"geo_distance":{"distance":"'+tempArray[2].trim()+'","position":{"lat": '+lat+',"lon":'+lon+'}}}';
                                option.filter = JSON.parse(distance);                                
                            }
                            
                        }
                        
                        break;
                    case 'param':
                        if (tempArray[2] === 'url') {
                            tempValueParam = params['paramId'];
                            //fix for duplicate ElasticSearch char "-"
                            if (tempArray[1].trim() === 'id') {
                                option.size = 1;
                            }
                        } 
                        else if (tempArray[2] === 'friendlyUrl') {
                            tempValueParam = params['friendlyUrl'];
                            //fix for duplicate ElasticSearch char "-"
                            if (tempArray[1].trim() === 'friendlyUrl') {
                                option.size = 1;
                            }
                        }
                        else if (tempArray[2] === 'parentId') {
                            tempValueParam = params['paramId'];
                        }
                        else if (tempArray[2].includes('parent')) {
                            var objectName = tempArray[2].split('.')[1];
                            if (parent != undefined && parent[objectName].length > 0) {
                                tempValueParam = parent[objectName].toString().replaceAll(","," ");
                                if(tempArray[1] == 'id') {
                                    isSortByParamIds = true;
                                    sortByParamName = tempArray[1];
                                    sortByParamIds = parent[objectName].toString();
                                }else if(tempArray[1] == 'esId'){
                                    isSortByParamIds = true;
                                    sortByParamName = tempArray[1];
                                    sortByParamIds = parent[objectName].toString();
                                }
                            } else {
                                tempValueParam = '00000001-0001-0001-0001-000000000001';
                            }

                            
                        }
                        // else if (tempArray[2] === 'preParamIds') {
                        //     tempValueParam = Common.preParamIds;
                        // }
                        else if (tempArray[2].includes('localStorage')) {
                            
                            tempValueParam = localStorage.getItem(tempArray[2].split('.')[1]);
                            //sessionStorage.getItem
                        }
                        else if (tempArray[2].includes('cookies')) {
                            
                            tempValueParam = Common.getCookie(tempArray[2].split('.')[1]);
                            //sessionStorage.getItem
                        }
                        else if (tempArray[2].includes('sessionStorage')) {                            
                            tempValueParam = sessionStorage.getItem(tempArray[2].split('.')[1]);
                            //sessionStorage.getItem
                        }                        
                        else if (tempArray[2] === 'dataSection') {
                            console.log(Common.tempDataSection);
                            if (Common.tempDataSection.categoryIds != undefined) {
                                tempValueParam = Common.tempDataSection.categoryIds;
                            }
                            else {
                                tempValueParam = '00000001-0001-0001-0001-000000000001';
                            }
                        }
                        else if (tempArray[2].trim() == '') {
                            tempValueParam = '00000001-0001-0001-0001-000000000001';
                        }
                        else if (tempArray[2].trim() == 'keyword') {
                            tempValueParam = params['paramKeyword'];
                        }
                        else if (tempArray[2].trim().includes('detaildata')  && Common.detailData != null && Common.detailData != undefined  && Common.detailData != '') {
                            tempValueParam = Common.detailData.data[0][tempArray[2].split(".")[1]];                                                        
                        }
                        else {         
                            if(tempArray[2] === 'preParamIds'){
                                tempValueParam = Common.preParamIds;
                            }else{
                                tempValueParam = tempArray[2];
                                Common.preParamIds = tempValueParam;
                            }
                            
                            
                            if(tempArray[1] == 'id') {
                                
                                // var sizeFunction = arrayFunction.find(e=> e.includes('size'));
                                // if(sizeFunction != undefined){
                                //     var size = Common.page*sizeFunction.split(':')[1];
                                //     var currentValue = tempValueParam.split(',');                    
                                //     var fromFunction = arrayFunction.find(e=> e.includes('from'));
                                //     if(fromFunction != undefined){
                                //         var from = fromFunction.split(':')[1];
                                //         tempValueParam = currentValue.slice(from, from + size).toString();
                                //     }else{
                                //         tempValueParam = currentValue.slice(0,  size).toString();
                                //     }
                                // }



                                isSortByParamIds = true;
                                sortByParamName = tempArray[1];
                                sortByParamIds = tempValueParam;
                            }else if(tempArray[1] == 'esId'){
                                isSortByParamIds = true;
                                sortByParamName = tempArray[1];
                                sortByParamIds = tempValueParam;
                            }
                        }


                        if (tempValueParam == undefined || tempValueParam === '') break;
                        
                        var match = {};
                        if (tempArray[2] === 'keyword'){

                            match.default_field = tempArray[1].trim();
                            if(tempArray[1].trim() == 'name'){
                                match.fields = ["friendlyUrl", "name"];
                            }
                            match.query = "*" +tempValueParam + "*";
                        }else{
                            match[tempArray[1].trim()] = tempValueParam;
                            tempNameParam = tempArray[1].trim();
                        }
                        

                        if (tempArray[2] === 'url' || tempArray[2] === 'friendlyUrl') {
                            option.queries.push({
                                match_phrase: match
                            })
                        }                        
                        else if(tempArray[2] === 'keyword'){
                            option.queries.push({
                                query_string: match
                                //match: {name:"dcp15"}
                            })
                        }
                        else if(tempArray[1] == 'customerId'
                         && tempValueParam.split(",").length === 1
                         ){
                         
                            option.queries.push({
                                match_phrase: match
                            })
                        }
                        else {
                            option.queries.push({
                                match: match
                            })
                        }                        
                        break;
                    default:
                        break;
                }

                // //==========================
                // if(option.size != undefined && tempArray[1] == 'id'){
                //     var currentValue = tempValueParam.split(',');
                    
                //     if(option.from != undefined){
                //         tempValueParam = currentValue.slice(option.from, option.from + option.size);
                //     }else{
                //         tempValueParam = currentValue.slice(0,  option.size);
                //     }
                    
                // }

                // //==========================
            });

            
        }
        var returnData = null;
        if (tempArray[0] === 'language' || tempArray[0] == 'evoucher' || tempArray[0] == 'promotion' || tempArray[0] == 'form' || tempArray[0] == 'quiz') {
            returnData = Common.queryLanguageData(index, type, option);
        } else {
            returnData = Common.queryData(index, type, option);
        }

        if (tempArray[0].toLowerCase() == 'product') {

            for (let index = 0; index < returnData.data.length; index++) {
                const element = returnData.data[index];
                if (element['discounts'] != undefined) {
                    var tempPrice = this.buildPrice(element['discounts'], element['price']);

                    if (tempPrice["oldPrice"] != undefined) {
                        returnData.data[index]['price'] = tempPrice["price"];
                        returnData.data[index]['oldPrice'] = tempPrice["oldPrice"];
                        returnData.data[index]['percent'] = tempPrice["percent"];
                        returnData.data[index]['cash'] = tempPrice["cash"];
                    }
                }

                if(returnData.data[index]['relationProducts'] != undefined)                
                {
                    for (let subIndex = 0; subIndex < returnData.data[index]['relationProducts'].length; subIndex++){
                        const element = returnData.data[index]['relationProducts'][subIndex];
                        if (element['discounts'] != undefined) {
                            var tempPrice = this.buildPrice(element['discounts'], element['price']);
        
                            if (tempPrice["oldPrice"] != undefined) {
                                returnData.data[index]['relationProducts'][subIndex]['price'] = tempPrice["price"];
                                returnData.data[index]['relationProducts'][subIndex]['oldPrice'] = tempPrice["oldPrice"];
                                returnData.data[index]['relationProducts'][subIndex]['percent'] = tempPrice["percent"];
                                returnData.data[index]['relationProducts'][subIndex]['cash'] = tempPrice["cash"];
                            }
                        }        
                    }
                }

                // var temp = window.Merchant.products.find(e=> e.id == element.id);
                // if(temp == undefined){
                //     window.Merchant.products.push(element);
                // }
                
            }


        }

        if (tempArray[0].toLowerCase() == 'article') {

            for (let index = 0; index < returnData.data.length; index++) {
                if(returnData.data[index]['products'] != undefined)                
                {
                    for (let subIndex = 0; subIndex < returnData.data[index]['products'].length; subIndex++){
                        const element = returnData.data[index]['products'][subIndex];
                        if (element['discounts'] != undefined) {
                            var tempPrice = this.buildPrice(element['discounts'], element['price']);
        
                            if (tempPrice["oldPrice"] != undefined) {
                                returnData.data[index]['products'][subIndex]['price'] = tempPrice["price"];
                                returnData.data[index]['products'][subIndex]['oldPrice'] = tempPrice["oldPrice"];
                                returnData.data[index]['products'][subIndex]['percent'] = tempPrice["percent"];
                                returnData.data[index]['products'][subIndex]['cash'] = tempPrice["cash"];
                            }
                        }        
                    }
                }
                
            }
        }

        if (tempArray[0].toLowerCase() == 'shoppingcart') {

            for (let index = 0; index < returnData.data.length; index++) {
                if(returnData.data[index]['shoppingCartItems'] != undefined)                
                {
                    for (let subIndex = 0; subIndex < returnData.data[index]['shoppingCartItems'].length; subIndex++){
                        const element = returnData.data[index]['shoppingCartItems'][subIndex];
                        if (element['discounts'] != undefined) {
                            var tempPrice = this.buildPrice(element['discounts'], element['targetPrice']);
        
                            if (tempPrice["oldPrice"] != undefined) {
                                returnData.data[index]['shoppingCartItems'][subIndex]['targetPrice'] = tempPrice["price"];                                
                                returnData.data[index]['shoppingCartItems'][subIndex]['oldPrice'] = tempPrice["oldPrice"];
                                returnData.data[index]['shoppingCartItems'][subIndex]['percent'] = tempPrice["percent"];
                                returnData.data[index]['shoppingCartItems'][subIndex]['cash'] = tempPrice["cash"];
                                returnData.data[index]['shoppingCartItems'][subIndex]['total'] = tempPrice["price"]*returnData.data[index]['shoppingCartItems'][subIndex]['quantity'];
                                returnData.data[index]['total'] = returnData.data[index]['total'] - (tempPrice["oldPrice"] - tempPrice["price"])*returnData.data[index]['shoppingCartItems'][subIndex]['quantity'];
                            }
                        }        
                    }
                }
                
            }
        }

        for (let index = 0; index < returnData.data.length; index++){
            returnData.data[index].raw = JSON.stringify(JSON.stringify(returnData.data[index])).replaceAll("\"", "'");

            if(returnData.data[index].attributes != undefined)
            {
                returnData.data[index].attributes.forEach(function(val,i){           
                    if(val.code != undefined && val.code != '')
                    {
                        returnData.data[index][val.code.charAt(0).toLowerCase() + val.code.slice(1)] = val.attributeValues[0].value;
                    }
                    
                })
                console.log(returnData.data[index]);
            }
        }
        //bổ xung phần relationproduct của product
        //bổ xung phần product của article Salutation

        if (sortByParamName != '' && (isSortByParamIds || sortByParamName == 'friendlyUrl' || sortByParamName == 'id' || sortByParamName == 'esId')) {
            
            var returnDataSorted = [];
            if(sortByParamIds != '')
            {                
                $.each(sortByParamIds.split(','), function (i, val) {
                    var tempReturn = undefined;
                    if(sortByParamName == 'esId'){
                        tempReturn = returnData.data.find(e => e.esId === val);
                    }else{
                        tempReturn = returnData.data.find(e => e.id === val);
                    }
                    
                    if (tempReturn != undefined) {
                        returnDataSorted.push(tempReturn);
                    } else {
                        var tempReturnFriendlyUrl = returnData.data.find(e => e.friendlyUrl === val);
                        if (tempReturnFriendlyUrl != undefined) {
                            returnDataSorted.push(tempReturnFriendlyUrl);
                        }
                    }
                });
            }else{
                returnDataSorted = returnData.data;
            }
            
            
            console.log("returnDataSorted[0]");

            if (returnDataSorted.length == 1 && returnDataSorted[0].categories != undefined) {

                console.log(returnDataSorted[0]);
                console.log("returnDataSorted[0] ====");
                Common.tempDataSection["categoryIds"] = returnDataSorted[0].categories.map(e => e.id).toString();
                if (tempArray[0] === 'article' && window.merchantType === 1024 && returnDataSorted[0].categoryCustomerIds.length > 0)//Content merchant
                {
                    var flag = false;
                    returnDataSorted[0].categoryCustomerIds.forEach(element => {
                        if (window.userCategories.includes(element)) {
                            flag = true;
                        }
                    });
                    if (!flag) {
                        returnDataSorted[0].description = 'Bạn không có quyền đọc!'
                    }

                }
            }
            console.log(returnDataSorted);
            return { data: returnDataSorted, total: returnData.total };


        } else {
            console.log(returnData);
            return returnData;
        }


    },
   
    replaceSectionTemp: function (section, items, param) {
        var build = '';        
        console.log(section);
        $.each(items, function (i, item) {

            var tempItemBuild = section.outerHTML;    
            
            var attr = $(tempItemBuild).attr('labo-if');
            if (typeof attr !== typeof undefined && attr !== false) {
                if (Common.executeExpression(item, $(tempItemBuild).attr('labo-if'), tempItemBuild, i) === false) {
                    tempItemBuild = '';
                } else {
                    var conditionSections = $(tempItemBuild).find('[labo-if]');
                    if (conditionSections.length > 0) {
                        $.each(conditionSections, function (index, val) {

                            if (Common.executeExpression(item, $(val).attr('labo-if'), val, i) === false && $(this).closest("[repeat]").attr("repeat") === $(tempItemBuild).attr("repeat").split("|")[0]) {
                                //temp.remove(val);
                                tempItemBuild = tempItemBuild.replace(val.outerHTML, '');
                            }
                        });
                    }
                }
            } else {                
                var conditionSections = $(tempItemBuild).find('[labo-if]');                
                if (conditionSections.length > 0) {
                    $.each(conditionSections, function (index, val) {
                        //console.log($(this).closest("[repeat]").attr("repeat") + "----" + $(tempItemBuild).attr("repeat").split("|")[0]);
                        

                        if (Common.executeExpression(item, $(val).attr('labo-if'), val, i) === false 
                        //===================
                        && $(this).closest("[repeat]").attr("repeat").split("|")[0] === $(tempItemBuild).attr("repeat").split("|")[0]
                        //==========================
                        ) {

                            tempItemBuild = tempItemBuild.replace(val.outerHTML, '');
                        }
                    });
                }
            }     
          
            var subSections = $(section).find('[repeat]');
            if (subSections.length > 0) {
                $.each(subSections, function (i, subSection) {
                    if (item[$(subSection).attr('repeat')] === undefined) {
                        var subParam = {};
                        subParam["paramId"] = item.id;
                        //subParam["parent"] = item;
                        var temp = Common.getData($(subSection).attr('repeat'), subParam,item);                        
                        if (temp != undefined) {    
                            //console.log(temp.data);
                            tempItemBuild = tempItemBuild.replace(subSection.outerHTML, Common.replaceSectionTemp(subSection, temp.data, subParam));
                        }else{
                            tempItemBuild = tempItemBuild.replace(subSection.outerHTML, "");
                        }
                    } else {
                        if(item[$(subSection).attr('repeat')].length > 0){
                            tempItemBuild = tempItemBuild.replace(subSection.outerHTML, Common.replaceSectionTemp(subSection, item[$(subSection).attr('repeat')]), param);
                        }   else{
                            tempItemBuild = tempItemBuild.replace(subSection.outerHTML, "");
                        }        
                        
                    }

                });
            }

         
///old  

          $(tempItemBuild).find('[labo-if]').each(function(){              
                
                tempItemBuild = tempItemBuild.replace('labo-if="'+$(this).attr('labo-if')+'"','');
            });   


        var tempVariables = Common.findVariable(/{{\s*[^}]*}}/g, tempItemBuild);
        $.each(tempVariables, function (index, variable) {
            tempItemBuild = Common.replaceVariable(item, variable, tempItemBuild);
        });
            
            build += tempItemBuild;
        });
        
        return build;
    },
    executeExpression: function (item, rootExpression, build, indexItem) {

        var returnFlag = true;

        var expressions = rootExpression.split("&&");

        expressions.map((expression, i) => {
            var flag = null;
            if (expression == 'oldPrice > 0') {
                console.log(item);
            }

            var tempArray = expression.trim().split(' ');
            if (tempArray.length != 3) return build;
            var value = 0;
            if (tempArray[0].toLowerCase() === 'index') {
                value = indexItem;
            }
            else if (tempArray[0].toLowerCase() === 'userlogin') {
                value = window.userLogin.toString().toLowerCase();
            }
            // else if (tempArray[2].toLowerCase() === 'datetimenow') {
            //     value = new Date();
            // }
            else if (item[tempArray[0]] == undefined) {
                value = 0;
            }
            else if(tempArray[0].toLowerCase().includes("datetime"))
            {              
                value = item[tempArray[0]];
                //tempArray[2] = new Date();
            }
            else if (!Array.isArray(item[tempArray[0]])) {
                value = item[tempArray[0]];
            }
            else {
                value = item[tempArray[0]].length;
            }


            switch (tempArray[1]) {
                case '>':
                    // if(isNaN(parseInt(tempArray[2]))){
                    //     flag = value > tempArray[2];
                    // }
                    // else 
                    if(tempArray[2] === 'datetimenow')
                    {
                        // var d = getDateTimeUtc();
                        // console.log(addMinutes(d, d.getTimezoneOffset()))
                        
                        flag = new Date(value) > new Date();
                    }
                    else{
                        flag = value > parseInt(tempArray[2]);
                    }
                    
                    break;
                case '<':
                    // if(isNaN(parseInt(tempArray[2]))){
                    //     flag = value < tempArray[2];
                    // }
                    // else
                     if(tempArray[2] === 'datetimenow')
                    {
                        // var d = getDateTimeUtc();
                        // console.log(d.getTimezoneOffset() + " ---- " + addMinutes(d, d.getTimezoneOffset()));
                        // console.log(d);

                        flag = new Date(value) <  new Date();
                    }else{
                        flag = value < parseInt(tempArray[2]);
                    }
                    
                    break;
                case '==':                    
                    flag = value == tempArray[2];
                    break;                
                case '!=':
                    flag = value != tempArray[2];
                    break;                
                case 'is':
                    flag = typeof item[tempArray[0]] == tempArray[2];
                    break;
                case 'isnot':
                    flag = typeof item[tempArray[0]] != tempArray[2];
                    break;
                default:
                    flag = false;
                    break;
                //return build;
            }

            if (!flag) {
                returnFlag = flag;
            }
        })

        return returnFlag;
    },

    executeExpressionIf: function (indexItem, item, attr) {
        var expression = attr.substring(0, attr.indexOf("?"));
        var strTrue = attr.substring(attr.indexOf("?") + 1, attr.indexOf(":"));
        var strFalse = attr.substring(attr.indexOf(":") + 1);
        var tempArray = expression.split(' ');
        if (tempArray.length != 3) return '';
        var value = 0;
        if (tempArray[0].toLowerCase() === 'index') {
            value = indexItem;
        } else {
            if (item[tempArray[0]] == undefined) return false;
            value = item[tempArray[0]].length;
        }
        var flag = false;
        switch (tempArray[1]) {
            case '>':
                flag = value > parseInt(tempArray[2]);
            case '<':
                flag = value < parseInt(tempArray[2]);
            case '==':
                flag = value == parseInt(tempArray[2]);
        }
        if (flag) return strTrue;
        return strFalse;
    },

    replaceVariable: function (item, variable, build) {
        var tempArray = variable.slice(2, variable.length - 2).split('|');
        if (tempArray.length === 0) return 'invalid variable!';

        //Lấy dữ liệu theo tên.
        //ví dụ: item["name"] là object, item["images"] là array
        var nameVariable = tempArray[0];

        var subNameVariables = nameVariable.split('.');
        var tempData = '';
        if (subNameVariables.length > 1) {
            var tempSubData = item[subNameVariables[0]];
            if (Array.isArray(tempSubData)) {
                $.each(tempSubData, function (i, val) {
                    tempData += val[subNameVariables[1]] + ' ';
                });
            } else {
                try {
                    tempData = tempSubData[subNameVariables[1]];
                } catch{ }

            }

        } else {
            tempData = item[nameVariable];
        }

        if (nameVariable === 'currentLink') {
            tempData = window.location.href;
        } else if (nameVariable === 'currentHost') {
            tempData = window.location.hostname;
        } else if (nameVariable === 'currentLanguageCode') {
            tempData = window.languageCode;
        }

        //ko hieu de lam gi
        if(tempData == undefined) return build.replace(variable, "");

        //Lấy function filter dạng mảng.
        var arrayFunction = tempArray.slice(1);
        if (arrayFunction.length > 0) {
            $.each(arrayFunction, function (i, val) {
                var functionName = val.split(':')[0];
                switch (functionName.toLowerCase()) {
                    case 'substring':
                        var etc = '';
                        if (val.split(':')[2] != undefined) etc = val.split(':')[2];
                        if (val.split(':')[1] != undefined) tempData = Common.subStringWithLastSpace(tempData, parseInt(val.split(':')[1]), etc);
                        break;
                    case 'rewrite':
                        tempData = Common.rewriteUrl(tempData);
                        break;
                    case 'uppercase':
                        tempData = tempData.toUpperCase();
                        break;
                    case 'lowercase':
                        tempData = tempData.toLowerCase();
                        break;
                    case 'formatdatetime':
                        if (val.split(':')[1] != undefined) {
                            tempData = Common.formatDateTime(tempData, val.split(':')[1]);
                        } else {
                            tempData = Common.formatDateTime(tempData);
                        }
                        break;
                    case 'formatmoney':
                        tempData = tempData.formatMoney(0, 3);
                        break;
                    case 'formatnumber':
                        try{
                            tempData = tempData.formatNumber(0, 3);                        
                        }     catch{
                            console.log(variable);
                            console.log(item);
                            console.log(build);
                        }
                        
                        break;
                    case 'getobjectbyindex':

                        if (val.split(':')[1] != undefined && Array.isArray(tempData) && tempData.length >= parseInt(val.split(':')[1])) {
                            tempData = tempData.sort(function (a, b) { return a.displayOrder - b.displayOrder })[parseInt(val.split(':')[1])]
                        } else {
                            tempData = tempData.sort(function (a, b) { return a.displayOrder - b.displayOrder })[0];
                        }
                        break;
                    case 'getthumbnailbyindex':
                        if (nameVariable != 'images') break;
                   
                        var tempFilter = tempData.filter(e => e.isFeatured == true);
                        if (tempFilter != undefined && tempFilter.length > 0) {
                            if (val.split(':')[1] != undefined && tempFilter.length >= parseInt(val.split(':')[1])) {
                                tempData = tempFilter.sort(function (a, b) { return a.displayOrder - b.displayOrder })[parseInt(val.split(':')[1])]
                            } else {
                                tempData = tempFilter.sort(function (a, b) { return a.displayOrder - b.displayOrder })[0];
                            }
                        } else {
                            tempData = null;
                        }
                        
                        break;
                    case 'getimagebyindex':
                            if (nameVariable != 'images') break;
                            var tempFilter = tempData;//.filter(e => e.isFeatured == false);
                            if (tempFilter.length > 0) {
                                if (val.split(':')[1] != undefined && tempFilter.length >= parseInt(val.split(':')[1])) {
                                    tempData = tempFilter.sort(function (a, b) { return a.displayOrder - b.displayOrder })[parseInt(val.split(':')[1])]
                                } else {
                                    tempData = tempFilter.sort(function (a, b) { return a.displayOrder - b.displayOrder })[0];
                                }
                            } else {
                                tempData = null;
                            }
                            break;
                    case 'getpath':

                        if (nameVariable != 'images') break;

                        if (Array.isArray(tempData) || tempData == null) {
                            tempData = Configuration.imageDefault;
                        }
                        else {
                            Common.staticUrlCurrent++;
                            if (Common.staticUrlCurrent >= Configuration.staticUrl.length) {
                                Common.staticUrlCurrent = 0;
                            }

                            tempData = Configuration.staticUrl[Common.staticUrlCurrent] + tempData['path'];
                            if (val.split(':')[1] != undefined) tempData += val.split(':')[1];
                        }
                        break;

                    case 'defaultvalue':
                        
                        if (tempData == undefined) {
                            if (val.split(':')[1] != undefined) {
                                tempData = val.split(':')[1];
                                
                            } else {
                                
                                tempData = "";
                            }
                        }

                        break;
                    // case 'scr':
                    //     if (val.split(':').length === 2) {
                    //         tempData = Configuration.staticUrl[Common.staticUrlCurrent] + tempData + split(':')[1];
                    //     } else {
                    //         tempData = Configuration.staticUrl[Common.staticUrlCurrent] + tempData;
                    //     }
                    //     break;                    
                    default:

                        break;
                }
            });
        }
        
        //if(tempData == undefined) return build.replace(variable, "-");
       
        return build.replace(variable, tempData);
    },

    change_alias(alias) {
        if (alias == null) {
            return '';
        }
        var str = alias;
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
        str = str.replace(/ + /g, " ");
        str = str.trim();
        return str;
    },
    rewriteUrl(string) {
        return Common.change_alias(string).replace(/\s/g, '-');
    },

    formatName: function (str, kyTu) {
        if (str.length < kyTu) {
            return str;
        }
        str = str || "";
        var temp = str.substring(0, kyTu);
        temp = temp.substring(0, temp.lastIndexOf(" "));
        return temp + '...';

    },

    subStringWithLastSpace: function (str, length, etc) {
        if (str === undefined) return '';
        if (str.length < length) {
            return str;
        }
        if (etc == undefined) {
            etc = '';
        }
        str = str || "";
        var temp = str.substring(0, length);
        temp = temp.substring(0, temp.lastIndexOf(" "));
        return temp + etc;

    },
    toTitleCase: function (string) {
        string = string.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        return string;
    },

    getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None; Secure;";
    },
    deleteCookie: function (cname) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    isGuid(stringToTest) {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    },
    clearFormData($selector) {
        $selector.find('input, textarea').each(function () {
            $(this).val('');
        });
    },
    formatDateTime(date, format) {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        //console.log(date.toIsoString);
        //var temp = moment(date);
        //console.log(temp);

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();            
         

        var hour = d.getHours();
        var min = d.getMinutes();
        var sec = d.getSeconds();
        //console.log(hour);
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (format == undefined) {
            return [year, month, day].join('-') + ' ' + hour + ':' + min + ':' + sec;
        }
        else {
            switch (format) {
                case "yyyy/MM/dd hh:mm":
                    return [year, month, day].join('-') + " " + [hour, min].join(':');
                case "yyyy-mm-dd":
                    return [year, month, day].join('-');
                case "dd-mm-yyyy":
                    return [day, month, year].join('-');
                case "mm-dd-yyyy":
                    return [month, day, year].join('-');
                case "MM":
                    return month;
                case "MMM":
                    return monthNames[d.getMonth()]
                case "MMM dd":
                    return monthNames[d.getMonth()] + ' ' + day
                case "DD":
                    return day;
                case "YYYY":
                    return year;
                case "mm":
                    return (min < 10) ? '0' + min.toString() : min;                    
                case "ss":
                    return sec;
                case "hh":
                    return (hour < 10) ? '0' + hour.toString() : hour;
                case "hh-mm-ss":
                    return hour + ':' + min + ':' + sec;
                case "timezone":
                    var offset = new Date().getTimezoneOffset();
                    var timezoneHours = parseInt(offset/60);
                    var munisTimezone = '';
                    if(Math.abs(offset%60) != 0){
                        munisTimezone += ":" + Math.abs(offset%60);
                    }
                    if(timezoneHours > 0){
                        return "-" + timezoneHours + munisTimezone;
                    }else{
                        return "+" + Math.abs(timezoneHours) + munisTimezone;
                    }
                    
                default:
                    return [year, month, day].join('-') + ' ' + hour + ':' + min + ':' + sec;

            }

        }
    },

    //lam tron den 0.5
    roundHalf(num) {
        return Math.round(num * 2) / 2;
    },
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    getImgUrl(data, option) {
        if (option == undefined) {
            option = "";
        }
        if (data.images.length > 0) {
            return Configuration.imageRoot + data.images[0].path + option;

        } else {
            return Configuration.imageDefault;
        }

    },
    getPathUrl(data, option) {
        if (option == undefined) {
            option = "";
        }
        if (data !== undefined) {
            return Configuration.imageRoot + data.path + option;

        } else {
            return Configuration.imageDefault;
        }
    },
    getImageThumbnail(images, resizeMode) {
        resizeMode = resizeMode || '';
        var path = Configuration.imageDefault + resizeMode;

        if (images != undefined && images.length > 0) {
            $.each(images, function (i, val) {
                if (val.isFeatured == true) {
                    path = Configuration.imageRoot + val.path + resizeMode;
                }
            });
        }
        return path;
    },
    getImageNotThumbnail(images, resizeMode) {
        resizeMode = resizeMode || '';
        var path = Configuration.imageDefault + resizeMode;

        if (images != undefined && images.length > 0) {
            $.each(images, function (i, val) {
                if (val.isFeatured != true) {
                    path = Configuration.imageRoot + val.path + resizeMode;
                }
            });
        }
        return path;
    },

    queryData(index, type, option, handleData) {

        if (index != '') {
            index += '/';
        }

        if (type != '') {
            type += '/';
        }

        var clearDefaultQuery = false;
        var returnData = [];
        var from = 0;
        var size = 10;
        var queries = null;
        var totalQuery = 0;
        var queriesMustNot = null;
        var sorts = [];
        var async = false;
        var languageId = undefined;
        var includes = ["id", 'name', 'description', 'subDescription', 'images'];

        if (option != undefined) {
            if (option.from != undefined) {
                from = option.from;
            }

            if (option.size != undefined) {
                size = option.size;
            }
            if (option.includes != undefined) {
                includes = option.includes;
            }

            if (option.queriesMustNot != undefined) {
                queriesMustNot = option.queriesMustNot;
            }

            if (option.queries != undefined) {
                queries = option.queries;
            }
            if (option.sorts != undefined) {
                sorts = option.sorts;
            }

            if (option.async != undefined) {
                async = option.async;
            }

            if (option.clearDefaultQuery != undefined) {
                clearDefaultQuery = option.clearDefaultQuery;
            }
            // if (option.languageId != undefined) {
            //     languageId = option.languageId;
            // }
        }
        // var searchObject = {
        //     "_source": {

        //     },
        //     query: {
        //         bool: {
        //             "must": [                        
        //                 {
        //                 match_phrase: {
        //                     merchantId: Configuration.merchantId
        //                 }
        //             }, {
        //                 "match": {
        //                     "languageId": window.languageId//this.getCookie(Configuration.currentLanguage)
        //                 }
        //             }],
        //             "must_not": [],
        //             "filter":{}
        //         }
        //     },
        //     sort: []
        // };

       var searchObject = {
            "_source": {

            },
            query: {
                bool: {
                    "must": [                        
                        {
                            bool:{
                                should:[
                                    {
                                            match_phrase: {
                                                merchantId: Configuration.merchantId
                                        }
                                    },
                                    {
                                        query_string: {
                                            query: Configuration.merchantId.replaceAll("-",""),
                                            default_field: "referenceMerchantIds"
                                    }
                                }
                                ]
                            }
                            
                        
                        }
                    , {
                        "match": {
                            "languageId": window.languageId//this.getCookie(Configuration.currentLanguage)
                        }
                    }],
                    "should":[

                    ],
                    "must_not": [],
                    "filter":{}
                }
            },
            sort: []
        };

        searchObject._source.includes = includes;

        if (clearDefaultQuery) {
            searchObject.query.bool.must = [];
        }
        if (option.filter != undefined) {
            searchObject.query.bool.filter = option.filter;
        }
        if (queries != null) {
            $.each(queries, function (i, val) {
                searchObject.query.bool.must.push(val);
            });

        }

        if (queriesMustNot != null) {
            $.each(queriesMustNot, function (i, val) {
                searchObject.query.bool.must_not.push(val);
            });

        }
        if (sorts != null) {
            $.each(sorts, function (i, val) {
                searchObject.sort.push(val);
            });
        }

        var xhr = new XMLHttpRequest();
        //Starts the variable xhr as the variable for the request
        xhr.open("POST", Configuration.esUrl + index + type + "_search?from=" + from + "&size=" + size + "", async);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa('amara:dSPKMcdQkG5X97b'));
        //Runs method 'open' which defines the request

        //Sends the request
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var temp = JSON.parse(xhr.responseText);
                    totalQuery = temp.hits.total;
                    $.each(temp.hits.hits, function (i, val) {
                        returnData.push(val._source);
                    });

                    if (handleData != undefined) {
                        handleData(returnData);
                    }

                } else {
                    try {
                        console.error(xhr.statusText);
                    } catch{ }

                }
            }
        };
        xhr.onerror = function (e) {
            try {
                console.error(xhr.statusText);
            } catch{ }

        };
        xhr.send(JSON.stringify(searchObject));
        return {
            data: returnData,
            total: totalQuery,
        };

    },
    queryLanguageData(index, type, option, handleData) {

        if (index != '') {
            index += '/';
        }

        if (type != '') {
            type += '/';
        }

        var clearDefaultQuery = false;
        var returnData = [];
        var from = 0;
        var size = 10;
        var queries = null;
        var totalQuery = 0;
        var queriesMustNot = null;
        var sorts = [];
        var async = false;
        var languageId = undefined;
        var includes = ["id", 'name', 'description', 'subDescription', 'images'];

        if (option != undefined) {
            if (option.from != undefined) {
                from = option.from;
            }

            if (option.size != undefined) {
                size = option.size;
            }
            if (option.includes != undefined) {
                includes = option.includes;
            }

            if (option.queriesMustNot != undefined) {
                queriesMustNot = option.queriesMustNot;
            }

            if (option.queries != undefined) {
                queries = option.queries;
            }
            if (option.sorts != undefined) {
                sorts = option.sorts;
            }

            if (option.async != undefined) {
                async = option.async;
            }

            if (option.clearDefaultQuery != undefined) {
                clearDefaultQuery = option.clearDefaultQuery;
            }
            // if (option.languageId != undefined) {
            //     languageId = option.languageId;
            // }
        }
        var searchObject = {
            "_source": {

            },
            query: {
                bool: {
                    "must": [],
                    "must_not": []
                }
            },
            sort: []
        };
        searchObject._source.includes = includes;

        if (clearDefaultQuery) {
            searchObject.query.bool.must = [];
        }

        if (queries != null) {
            $.each(queries, function (i, val) {
                searchObject.query.bool.must.push(val);
            });

        }

        if (queriesMustNot != null) {
            $.each(queriesMustNot, function (i, val) {
                searchObject.query.bool.must_not.push(val);
            });

        }
        if (sorts != null) {
            $.each(sorts, function (i, val) {
                searchObject.sort.push(val);
            });
        }

        var xhr = new XMLHttpRequest();
        //Starts the variable xhr as the variable for the request
        xhr.open("POST", Configuration.esUrl + index + type + "_search?from=" + from + "&size=" + size + "", async);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader('Authorization', 'Basic ' + btoa('amara:dSPKMcdQkG5X97b'));
        //Runs method 'open' which defines the request

        //Sends the request
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var temp = JSON.parse(xhr.responseText);
                    totalQuery = temp.hits.total;
                    $.each(temp.hits.hits, function (i, val) {
                        returnData.push(val._source);
                    });

                    if (handleData != undefined) {
                        handleData(returnData);
                    }

                } else {
                    console.error(xhr.statusText);
                }
            }
        };
        xhr.onerror = function (e) {
            console.error(xhr.statusText);
        };
        xhr.send(JSON.stringify(searchObject));
        return {
            data: returnData,
            total: totalQuery,
        };

    }
};
export default Common;

