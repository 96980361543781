
import Common from './utils/common';


var Configuration = {
    url_graphQl: "https://apicms.izzi.asia/graphql/",
    repeatPattern: /<\s*repeat[^>]*>([\s\S]*?)<\s*\/\s*repeat>/g,
    repeatRegular: /<[^>]*repeat[^>]*>([\s\S]*?)<[^>]+>/g,
    categoryIndex: 'categories',
    categoryType: 'category',
    productIndex: 'products',
    productType: 'product',
    pageSizeArticles: 9,
    pageSizeResort: 3,
    pageSizeProducts: 9,
    //url
    imageRoot: 'https://static.izzi.asia',
    esUrl: 'https://es.izzi.asia/',
    staticUrl: [
        // 'https://cdn-static6.foodizzi.com/'
        // , 'https://cdn-static7.foodizzi.com/'
        'https://static.izzi.asia'
        // , 'https://static1.foodizzi.com/'
        // , 'https://static2.foodizzi.com/'
        // , 'https://static3.foodizzi.com/'
        // , 'https://static4.foodizzi.com/'
        // , 'https://static5.foodizzi.com/'
    ],
    imageDefault: 'https://static.izzi.asia/images/no-image.png',
    apiUrl: 'https://apiRestful.izzi.asia',
    //merchant
    merchantId: '',
    //language
    // languageVi: '838aef56-78bb-11e6-b5a6-00155d582814',
    // languageEn: "e3509252-c42d-4ae5-9779-d4805a687a8e",
    //token
    currentLanguage: 'tokenLanguage',
    token: 'izziToken',
    //item per page
    itemsPerPage: 9,

}


Configuration.merchantId = Common.getCookie('merchantId');

export default Configuration;

